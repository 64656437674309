export const AppTexts = {
  DashBoard: "Dashboard",
  DownloadDetails: "Download Details",
  Earnings: "Earnings",
  Orders: "Orders",
  Profile: "Profile",
  Purchases: "Purchases",
  FollowDetails: "Follow Details",
  Resources: "Resources",
  ReleaseForm: "Release Form",
  Wishlist: "Wishlist",
  SelectReleaseForms: "Select Release Forms",
  CreateNewWishlist: "Create a new wishlist",
  AddToExistingWishlist: "Add to existing wishlist",
  Cancel: "Cancel",
  Save: "Save",
  Trending: "Trending",
  BrowseAllCollections: "Browse All Collections",
  RegisterNow: "Register Now",
  ImageLicensing: "Image Licensing",
  JoinOurCommunityToday: "Join our community today",
  SignUpForMonthlyCreativeContentIdeas:
    "Sign-up for monthly creative content ideas.",
  Subscribe: "Subscribe",
  Copyright: "Copyright",
  PICHAStock: "PICHAStock",
  PICHAStockStudio: "PICHAStock Studio",
  AllRightsReserved: "All rights reserved",
  ViewAll: "View All",
  PichaStockInstagramGallery: "PichaStock Instagram Gallery,",
  Invoice: "Invoice",
  From: "From",
  To: "To",
  InvoiceDate: "Invoice Date",
  InvoiceId: "Invoice ID",
  PurchaseKey: "Purchase Key",
  PaymentStatus: "Payment Status",
  PaymentMethod: "Payment Method",
  InvoiceItems: "Invoice Items",
  ProductName: "Product name",
  Price: "Price",
  InvoiceTotals: "Invoice Totals",
  Discount: "Discount",
  Subtotal: "Subtotal",
  TotalPaid: "Total Paid",
  Login: "Login",
  SendRequest: "Send request",
  PopularCategories: "Popular Categories",
  Skip: "Skip",
  SelectAtLeastOneCategory: "Select at least one category",
  Continue: "Continue",
  InviteFriends: "Invite Friends",
  Invite: "Invite",
  PopularContributors: "Popular Contributors",
  FollowAtLeastOneContributor: "Follow at least one contributor",
  Unlike: "Unlike",
  Like: "Like",
  PurchaseNow: "Purchase now",
  DoYouHaveAmazingDesc:
    "Do you have amazing modern images / videos representing different afro communities you'd like to future for sale? just complete the form below and become part of our community.",
  ConfirmYouAre18years: "Please confirm that you are 18 years old",
  ByCreatingAccountIAgreeThat:
    "By creating an account, I agree that, I have read the",
  AgreeToReceiveUpdates:
    "I agree to receive updates about news, programs, and special events from PICHAStock. Please refer to PICHA’s",
  TermsOfService: "Terms of Service",
  PrivacyPolicy: "Privacy Policy",
  Register: "Register",
  AlreadyRegistered: "Already Registered",
  Or: "Or",
  ContinueWithFacebook: "Continue with Facebook",
  VideoUploadingInProgress: "Video uploading in progress",
  Edit: "Edit",
  Contributors: "Contributors",
  ActiveChallenges: "Active Challenges",
  Loading: "Loading ...",
  NoRecordFound: "No Record Found",
  DragAndDrop: "Drag and drop or browse to choose a file",
  Next: "Next",
  ClickHere: "Click here",
  ToAccessTheImages: "to access the images",
  JPEGFormatOnly: "JPEG format only",
  MinimumImageResolutionIs4MP: "Minimum image resolution is 4MP",
  MaximumImageResolutionIs100MP: "Maximum image resolution is 100MP",
  MaximumFileSizeIs45MB: "Maximum file size is 45MB",
  Deadline: "Deadline",
  Organizers: "Organizers",
  ReadyToWinTheChallenge: "Ready to win the challenge",
  Photographers: "Photographers",
  Challenges: "Challenges",
  PastChallenges: "Past Challenges",
  PrivateOnlyViewableByYou: "Private - only viewable by you",
  PublicViewableByAnyone: "Public - viewable by anyone",
  Create: "Create",
  TotalDownloads: "Total Downloads",
  TotalLike: "Total Likes",
  TotalOrders: "Total Orders",
  TotalViews: "Total Views",
  TotalEarnings: "Total Earnings",
  Media: "Media",
  TimeSpan:'Time Span',
  RecommendedForYou:'Recommended for you',
  ShareWithYourCommunity: 'Share this with your Community',
  RecognizablePeople:'Recognizable people or property?',
  Achievement:'Achievement',
  AddNewReleaseForm: 'Add new Release Form',
  LatestPhoto: 'Latest Photo',
  LatestVideo: 'Latest video',
  ShowCase: 'Show case',
  KeywordMsg:'Keyword (Use Comma or enter for next tag)',
  AiTermsCertify : ' I certify that the people or property in this resource are not based on any identifiable person or property. Do not check the box if you have used a real person or existing property to generate your AI resource. If this is the case, please upload a template or image rights authorization.',

  AiNoteTerms : "Note: please consult our requirements before submitting content created using generative artificial intelligence tools. Remember: you can't submit content generated with a prompt that includes the name of one or more other artists.",

  isAIGeneratedText: 'Created using generative AI tools',
  Assets:'Assets',
  Followers: 'Followers',
  LoadMore: 'Load More',
  CheckPeopleAreFictional: 'Please Check People and Property are Fictoinal to continue',
  Error:'Error',
  Share: 'Share',
  SelectImageToContinue:'Select an image / Video to continue',
  WhyJoinUsTitle: 'Why do you want to join PichaStock?',
  ImHereToDownload: "I'm here to download licensed photos and videos",
  DonwloadContent: 'I would like to download licensed content',
  ImHereToShare: "I'm here to share my photos and videos with the world.",
  IWillBecomeContributor: 'I would like to become a contributor',
  JoinUsDescriptionText: 'PICHA believes that original stories can change perceptions. And we are here to do just that. To bring the voices, faces, and stories of modern Afro and Black communities to the world and the brands we serve.',
  ExploreOurCollection: 'Explore our Collection',
  SendUsArief: 'Send us a brief',
  StandardImages:'Standard Images',
  StandardImagesDesc:'Great for basic web use like blogs, articles, brochures, and small prints at around the size of a postcard.',
  LargeImages:'Large Images',
  LargeImagesDesc:'These are big enough for almost any need (i.e., full-page prints, any web need…).',
  Premium: 'Premium',
  PremiumDesc: ' A collection of exceptional visuals, including the breaking barriers collection.',  
  Download: 'Download',
  ByCreatingAnAccount:'By creating an account, I agree that: I have read the',
  IAgreeToReceiveUpdates: "I agree to receive updates about news, programs, and special events from PICHA. Please refer to PICHA’s",
  And: 'and',
  SignIn: 'Sign in',
  RememberMe: 'Remember me',
  ForgotPassword: 'Forgot Password',
  DontHaveAnAccount: "Don't have an account?",
  JoinPichastock: 'Join Pichastock',
  Update: 'Update',
  ViewMyPortfolio: 'View my Portfolio',
  UploadAvatar: 'Upload Avatar',
  UploadCover: 'Upload Cover Image',
  CheckOut: 'Checkout',





}; 